/*----------------------------------------------------------*\
    Header

    All styles for the main page header are below. This
    includes the navigation elements as well.

\*----------------------------------------------------------*/
body.nav-overlay {
	overflow: hidden;
	position: fixed;
}
.page-header {
	width: 100%;
	padding: 10px 0;
	position: absolute;
	z-index: 100;
	img {
		margin-bottom: 0;
	}
	a {
		border-bottom: none;
	}
	.inner {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	.logo {
		padding-right: 20px;
		img {
			width: auto;
			height: 43px;
		}
	}
	.nav-right {
		display: flex;
		justify-content: flex-end;
		align-items: center;
	}
	.nav-icons {
		display: flex;
		justify-content: flex-end;
		align-items: center;
		.icon-text {
			display: none;
		}
		.icon {
			height: 22px;
		}
		.icon-wrapper {
			margin-right: 20px;
		}
	}
	.main-nav {
		display: none;
	}
}

@include breakpoint(900px) {
	.page-header {
		.nav-toggle {
			display: none;
		}
		.nav-right {
			display: block;
		}
		.main-nav {
			display: block;
		}
		.logo {
			align-self: flex-end;
			img {
				height: 61px;
			}
		}
		.nav-icons {
			text-align: right;
			margin-bottom: 32px;
			display: block;
			.icon-wrapper {
				background-color: rgba(0, 0, 0, 0.5);
				padding: 7px 14px;
				border-radius: $border-radius;
				display: inline-block;
				a {
					display: flex;
					align-items: center;
					&:hover, &:focus {
						color: $white;
					}
				}
				&:last-child {
					margin-right: 0;
				}
			}
			.icon-text {
				font-size: rem-calc(16);
				display: inline-block;
			}
			.icon {
				margin-right: 5px;
				display: flex;
				align-items: center;
				svg {
					height: 16px;
					width: auto;
				}
			}
		}
	}
}
/*------------------------------------*\
    @group Navigation
\*------------------------------------*/
.main-nav {
	font-size: rem-calc(21);
	font-weight: $main-bold;
	line-height: 1.14;
	text-align: right;
	a {
		color: $white;
		&:hover, &:focus {
			color: $brand;
		}
	}
	ul {
		padding: 0;
		margin: 0;
		li {
			margin: 0 0 0 64px;
			padding: 0;
			display: inline-block;
			&.selected a {
				color: $brand;
			}
			&:first-child {
				margin-left: 0;
			}
		}
	}
}
.offscreen-nav {
	background-color: $dark-gray;
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	display: none;
	z-index: 500;
	a {
		border-bottom: none;
	}
	.inner {
		position: relative;
		padding-top: 10px;
		padding-bottom: 10px;
	}
	.logo {
		img {
			height: 43px;
			margin: 0;
		}
	}
	.close-nav {
		background-image: linear-gradient(45deg, $dark-gray 0%, $dark-gray 50%, #444444 50%, #444444 100%);
		width: 90px;
		height: 90px;
		color: $white;
		padding: 10px;
		cursor: pointer;
		position: absolute;
		right: 0;
		top: 0;
		display: flex;
		align-items: flex-start;
		justify-content: flex-end;
		span {
			display: inline-block;
			line-height: 1;
			&:hover, &:focus {
				color: $brand;
			}
		}
		svg {
			fill: currentColor;
		}
	}
	.nav {
		max-width: 450px;
		padding-top: 50px;
		margin: 0 auto;
		display: flex;
		align-items: stretch;
		justify-content: space-between;
		nav {
			flex-basis: 40px;
			a {
				font-size: rem-calc(28);
				font-weight: $main-bold;
				color: $white;
				line-height: 1;
				&:focus, &:hover {
					color: $brand;
				}
			}
			li {
				margin-bottom: 24px;
				&.selected a {
					color: $brand;
				}
			}
		}
	}
	.divider {
		width: 2px;
		border-left: solid 1px #1b1a1a;
		border-right: solid 1px  #303030;
		margin-left: 5%;
	}
	.nav-icons {
		margin-right: 10%;
		text-align: center;
		.icon-wrapper {
			margin-bottom: 36px;
			&:last-child {
				margin-bottom: 0;
			}
		}
		.icon-text {
			display: block;
		}
	}

}

@include breakpoint(900px) {
	.offscreen-nav {
		display: none !important;
	}
}
/* @end Navigation */