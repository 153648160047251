@font-face {
    font-family: "clarika-grotesque";
    src: url("/fonts/clarikagrot-regular-webfont.eot");
    src: url("/fonts/clarikagrot-regular-webfont.eot?#iefix") format("embedded-opentype"),
        url("/fonts/clarikagrot-regular-webfont.woff2") format("woff2"),
        url("/fonts/clarikagrot-regular-webfont.woff") format("woff"),
        url("/fonts/clarikagrot-regular-webfont.ttf") format("truetype"),
        url("/fonts/clarikagrot-regular-webfont.svg") format("svg");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "clarika-grotesque";
    src: url("/fonts/clarikagrot-bold-webfont.eot");
    src: url("/fonts/clarikagrot-bold-webfont.eot?#iefix") format("embedded-opentype"),
        url("/fonts/clarikagrot-bold-webfont.woff2") format("woff2"),
        url("/fonts/clarikagrot-bold-webfont.woff") format("woff"),
        url("/fonts/clarikagrot-bold-webfont.ttf") format("truetype"),
        url("/fonts/clarikagrot-bold-webfont.svg") format("svg");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}