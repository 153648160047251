/*----------------------------------------------------------*\
   Icon

   Default icon styling and sprite icon styles.

\*----------------------------------------------------------*/

/*------------------------------------*\
    @group Default Icon Style
\*------------------------------------*/

.icons {
	width: 1em;
	height: 1em;
	fill: currentColor;
	vertical-align: middle;
	display: inline-block;
	position: relative;
	top: -.1em;
}

/* @end Default Icon Style */