.hex-tiles {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-column-gap: 10px;
	margin-bottom: 25px;
}
.hex-tile {
	max-width: 180px;
	position: relative;
	margin: 16px auto 0;
	a {
		border-bottom: none;
		padding: 0;
		margin: 0;
		color: $light-gray;
		display: block;
		line-height: 1;
		position: relative;
		z-index: 20;
		&:hover, &:focus, &.active {
			color: $brand;
		}
		&::after {
			content: '';
			background-color: $brand;
			width: 26px;
			height: 80px;
			opacity: 0;
			visibility: hidden;
			display: block;
			position: absolute;
			bottom: -25px;
			left: calc(50% - 13px);
			transition: opacity .3s, visibilty .3s;
			z-index: 1;
		}
		&.active::after {
			opacity: 1;
			visibility: visible;
		}
	}
	svg {
		max-width: 100%;
		height: auto;
		position: relative;
		z-index: 20;
	}
}
.hex-tile-text {
	width: 100%;
	font-size: rem-calc(15);
	font-weight: $main-bold;
	color: $main-color;
	line-height: 1.13;
	text-align: center;
	padding-left: 20px;
	padding-right: 20px;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 21;
	img {
		width: auto;
		max-height: 30px;
		display: block;
		margin: 0 auto 5px;
	}
}
.hex-tile-border {
	fill: currentColor;
}
.hex-tile-details {
	border-top: solid 6px $brand;
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
	padding: 20px;
	position: relative;
	margin-top: 24px;
	> *:last-child {
		margin-bottom: 0;
	}
}
.hex-tile-details {
	display: none;
}

.full-hex-row {
    grid-column: 1/-1;
}

@include breakpoint(medium) {
	.hex-tiles {
		grid-template-columns: repeat(auto-fill, minmax(200px, 1fr))
	}
}

@include breakpoint(768px) {
	.hex-tiles {
		margin-bottom: 50px;
	}
	.hex-tile-text {
		img {
			max-height: 35px;
		}
	}
}

@include breakpoint(1000px) {
	.hex-tiles {
		grid-column-gap: 30px;
	}
	.hex-tile {
		max-width: 237px;
		width: 100%;
	}
	.hex-tile-text {
		font-size: rem-calc(21);
		padding: 0 30px;
		img {
			max-height: 100%;
		}
	}
}