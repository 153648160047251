/*----------------------------------------------------------*\
    Variables

    This file contains all (non-Foundation) globally-scoped
    variables used throughout the Sass files. We strongly
    encourage sticking to these variables instead of
    hard-coded values as often as possible.

\*----------------------------------------------------------*/

//Font families
$sans-serif-font: "clarika-grotesque", Lato, Helvetica, Arial, sans-serif;;
$serif-font: sans-serif;
$icon-font: 'StarterTemplate';
$heading-font: $sans-serif-font;
$main-font: $sans-serif-font;
$alt-font: $heading-font;

//Font weights
$main-normal: 400;
$main-bold: 700;
$alt-normal: 400;
$alt-bold: 700;
$heading-normal: $alt-normal;
$heading-bold: $alt-bold;

//Font sizes
$font-sizes: (
  small: (.9375rem, 1.67),
  default: (1.125rem, 1.5),
  large: (1.625rem, 1.31),
  small-desktop: (1rem, 1.5),
  default-desktop: (1.25rem, 1.5),
  large-desktop: (1.8125rem, 1.31)
);


//Styleguide colors
$brand:				 #f68d2e;
$brand-accent:		 #555555;
$light-gray:		 #cccccc;
$dark-gray:          #262626;
$white:              #FFFFFF;
$black:              #000000;
$yellow:			 #f4ba44;
$gray:               gray;

$colors: (
	'brand':			 $brand,
	'brand-accent':		 $brand-accent,
	'light-gray':        $light-gray,
	'dark-gray':         $dark-gray,
	'yellow':            $yellow,
	'white':             $white,
	'black':             $black
);

//Gradients, for use on backgrounds only
$gradients: (
	'white': linear-gradient(180deg, rgba(231, 231, 231, 1) 0%, rgba(255, 255, 255, 1) 100%)
);

//Transparent background colors
$brand-trans:        rgba($brand,0.8);
$black-trans:        rgba($black,0.7);

// Main body color, use when adding this color elsewhere (form fields as an example)
$main-color: $brand-accent;

//Link colors
$link-base: $brand;
$link-active: $brand;

//Social media brand colors
$social: (
	'twitter': 		 #00aced,
	'facebook': 	 #3b5998,
	'googleplus': 	 #dd4b39,
	'pinterest': 	 #cb2027,
	'linkedin': 	 #007bb6,
	'youtube': 		 #bb0000,
	'vimeo': 		 #aad450,
	'instagram': 	 #517fa4,
	'quora': 		 #a82400,
	'foursquare': 	 #0072b1,
	'stumbleupon': 	 #EB4823,
	'soundcloud': 	 #ff3a00
);

//Shadows
$text-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
$text-shadow-light: 1px 1px 1px rgb(255,255,255);
$box-shadow: 0 1px 3px rgba(0,0,0,.3);

//Rounded corners
$border-round: 1000px;
$border-radius: 5px;

//Section spacing
$full-section-spacing-small: 		50px;
$content-section-spacing-small: 	90px;
$content-sub-section-spacing-small: 60px;
$intro-section-spacing-small: 		30px;
$full-section-spacing: 				50px;
$content-section-spacing: 			150px;
$content-sub-section-spacing: 		90px;
$intro-section-spacing: 			60px;

//Hero backgrounds
$hero-bg: (
	'bg-bridge': bridge
);

$hero-bg-color: (
	'bg-bridge': #6d3e00
);