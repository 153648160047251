/*----------------------------------------------------------*\
    Main Content Section

    This file contains styles for the main content section,
    including sidebars.

\*----------------------------------------------------------*/

.page-content {
    background: $white;
    padding: $full-section-spacing 0 $full-section-spacing - 24;
    min-height: 50vh;
}
.narrow-content {
	max-width: 860px;
	margin: 0 auto;
}