/*----------------------------------------------------------*\
    Page Footer Section

    This file contains all styles for the footer section.

\*----------------------------------------------------------*/

.page-footer {
    background-color: $brand-accent;
    color: $white;
    padding: 20px;
    p {
    	@include font-size(small);
        margin: 0;
    }
}