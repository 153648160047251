/*----------------------------------------------------------*\
    Basestyles

    Base styles that are too small for individual files. The
    animate classes listed here will be the default for
    animated elements unless overriden.

\*----------------------------------------------------------*/

body {
	background: $white;
	font-family: $main-font;
	font-weight: $main-normal;
	color: $main-color;
	position: relative;
	@include font-size(default);
	@include breakpoint(768px) {
		@include font-size(default-desktop);
	}
}
p {
	font-size: 1em;
	line-height: 1.5;
	margin: 0 0 24px;
	&.large {
		@include font-size(large);
		margin-bottom: 18px;
	}
	&.small {
		@include font-size(small);
		margin-bottom: 8px;
	}
	&.normal {
		@include font-size(default);
		margin-bottom: 24px;
	}
}
a:not(.button) {
	color: $link-base;
	transition: text-decoration .3s;
	text-decoration: none;
	border-bottom: solid 1px currentColor;
	transition: border-color .2s, color .3s;
	&:hover,
	&:focus {
		color: $link-base;
		border-color: transparent;
	}
}
hr {
	margin: 20px 0;
}
.inner {
	@include xy-grid-container;
}

/* @end */