/*------------------------------------*\
    @group Hero
\*------------------------------------*/
.hero {
	height: 700px;
	background-size: cover;
	background-color: $dark-gray;
	background-position: right center;
	position: relative;
	&::before {
		content: '';
		background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.85) 0%, rgba(0, 0, 0, 0) 100%);
		width: 100%;
		height: 250px;
		display: block;
		position: absolute;
		left: 0;
		top: 0;
		z-index: 1;
	}
	.hero-text {
		background-color: $brand-trans;
		color: $white;
		padding: 20px;
		position: absolute;
		bottom: 0;
		z-index: 10;
		h1 {
			color: $white;
			margin-bottom: 18px;
		}
		p {
			margin: 0;
		}
	}
	.slider-bg-img {
		background-size: cover;
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		&.img-0 {background-image: url('https://harriscpa.imgix.net/hero/bg_bridge@2x.jpg?auto=format&w=540');}
		&.img-1 {background-image: url('https://harriscpa.imgix.net/hero/bg_foothills_skyline@2x.jpg?auto=format&w=540');}
		&.img-2 {background-image: url('https://harriscpa.imgix.net/hero/bg_dogs_river@2x.jpg?auto=format&w=540');}
		@media  only screen and (-webkit-min-device-pixel-ratio: 1.3),
		only screen and (-o-min-device-pixel-ratio: 13/10),
		only screen and (min-resolution: 120dpi) {
			&.img-0 {background-image: url('https://harriscpa.imgix.net/hero/bg_bridge@2x.jpg?auto=format');}
			&.img-1 {background-image: url('https://harriscpa.imgix.net/hero/bg_foothills_skyline@2x.jpg?auto=format');}
			&.img-2 {background-image: url('https://harriscpa.imgix.net/hero/bg_dogs_river@2x.jpg?auto=format');}
		}
	}
}

@include breakpoint(medium) {
	.hero .slider-bg-img {
		&.img-0 {background-image: url('https://harriscpa.imgix.net/hero/bg_bridge_med@2x.jpg?auto=format&w=960');}
		&.img-1 {background-image: url('https://harriscpa.imgix.net/hero/bg_foothills_skyline_med@2x.jpg?auto=format&w=960');}
		&.img-2 {background-image: url('https://harriscpa.imgix.net/hero/bg_dogs_river_med@2x.jpg?auto=format&w=960');}
		@media  only screen and (-webkit-min-device-pixel-ratio: 1.3),
		only screen and (-o-min-device-pixel-ratio: 13/10),
		only screen and (min-resolution: 120dpi) {
			&.img-0 {background-image: url('https://harriscpa.imgix.net/hero/bg_bridge_med@2x.jpg?auto=format');}
			&.img-1 {background-image: url('https://harriscpa.imgix.net/hero/bg_foothills_skyline_med@2x.jpg?auto=format');}
			&.img-2 {background-image: url('https://harriscpa.imgix.net/hero/bg_dogs_river_med@2x.jpg?auto=format');}
		}
	}
}

@include breakpoint(768px) {
	.hero {
		height: 900px;
		.hero-text {
			width: 75%;
			padding: 20px 30px 30px;
			display: flex;
			justify-content: flex-end;
			bottom: 0;
			right: 25%;
		}
		.hero-text-inner {
			max-width: 620px;
		}
	}
}

@include breakpoint(large) {
	.hero {
		height: 1000px;
		.hero-text {
			width: 65%;
			right: 35%;
		}
		.slider-bg-img {
			&.img-0 {background-image: url('https://harriscpa.imgix.net/hero/bg_bridge_lrg@2x.jpg?auto=format&w=1400');}
			&.img-1 {background-image: url('https://harriscpa.imgix.net/hero/bg_foothills_skyline_lrg@2x.jpg?auto=format&w=1400');}
			&.img-2 {background-image: url('https://harriscpa.imgix.net/hero/bg_dogs_river_lrg@2x.jpg?auto=format&w=1400');}
			@media  only screen and (-webkit-min-device-pixel-ratio: 1.3),
			only screen and (-o-min-device-pixel-ratio: 13/10),
			only screen and (min-resolution: 120dpi) {
				&.img-0 {background-image: url('https://harriscpa.imgix.net/hero/bg_bridge_lrg@2x.jpg?auto=format');}
				&.img-1 {background-image: url('https://harriscpa.imgix.net/hero/bg_foothills_skyline_lrg@2x.jpg?auto=format');}
				&.img-2 {background-image: url('https://harriscpa.imgix.net/hero/bg_dogs_river_lrg@2x.jpg?auto=format');}
			}
		}
	}
}

@include breakpoint(1300px) {
	.hero {
		.hero-text {
			width: 50%;
			right: 50%;
		}
	}
}

@include breakpoint(1400px) {
	.hero {
		.slider-bg-img {
			&.img-0 {background-image: url('https://harriscpa.imgix.net/hero/bg_bridge_xlrg@2x.jpg?auto=format&w=2400');}
			&.img-1 {background-image: url('https://harriscpa.imgix.net/hero/bg_foothills_skyline_xlrg@2x.jpg?auto=format&w=2400');}
			&.img-2 {background-image: url('https://harriscpa.imgix.net/hero/bg_dogs_river_xlrg@2x.jpg?auto=format&w=2400');}
			@media  only screen and (-webkit-min-device-pixel-ratio: 1.3),
			only screen and (-o-min-device-pixel-ratio: 13/10),
			only screen and (min-resolution: 120dpi) {
				&.img-0 {background-image: url('https://harriscpa.imgix.net/hero/bg_bridge_xlrg@2x.jpg?auto=format');}
				&.img-1 {background-image: url('https://harriscpa.imgix.net/hero/bg_foothills_skyline_xlrg@2x.jpg?auto=format');}
				&.img-2 {background-image: url('https://harriscpa.imgix.net/hero/bg_dogs_river_xlrg@2x.jpg?auto=format');}
			}
		}
	}
}

//Loops over $hero-bg in _variables.scss and creates files with mixin
@each $name, $filename in $hero-bg {
  .#{$name} {
	@include hero-image($filename: $filename);
	background-color: map-get($hero-bg-color, $name);
  }
}

/* @end Hero */