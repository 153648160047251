.bio-group {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-gap: 20px;
	margin-bottom: 75px;
	&.half-row {
		grid-template-columns: repeat(auto-fit, minmax(130px, max-content));
	}
	&.center-cards {
		justify-content: center;
	}
}
.bio-card {
	max-width: 300px;
	overflow: hidden;
	position: relative;
	a {
		border-bottom: none;
		padding: 0;
		margin: 0;
	}
	p {
		font-size: rem-calc(20);
		font-weight: $main-bold;
		line-height: 1.2;
		margin: 0;
		span {
			font-size: rem-calc(18);
			font-weight: $main-normal;
			display: block;
		}
	}
	img{
		margin-bottom: 10px;
	}
	.bio-trigger {
		position: absolute;
		right: 0;
		top: 0;
		img {
			position: relative;
			z-index: 10;
			margin: 10px;
		}
		&::before {
			content: '';
			background-color: rgba(0, 0, 0, 0.4);
			width: 130px;
			height: 96px;
			transform: rotate(50deg);
			display: block;
			position: absolute;
			right: -59px;
			bottom: -17px;
			z-index: 0;
			transition: background-color .3s;
		}
		&:hover, &:focus {
			&::before {
				background-color: $brand;
			}
		}
	}
}

@include breakpoint(medium) {
	.bio-group {
		grid-template-columns: 1fr 1fr 1fr;
		grid-gap: 30px;
		margin-bottom: 100px;
	}
	.bio-card {
		p {
			font-size: rem-calc(25);
			span {
				font-size: rem-calc(20);
			}
		}
		&.grid-col-3 {
			grid-column: 2;
		}
	}
}

@include breakpoint(large) {
	.bio-group {
		grid-template-columns: 1fr 1fr 1fr 1fr;
	}
	.bio-card {
		&.grid-col-2 {
			grid-column: 2;
		}
		&.grid-col-3 {
			grid-column: 3;
		}
	}
}

.bio-modal {
	background: $white;
	max-width: 1080px;
	width: auto;
	padding-bottom: 5px;
	margin: 20px auto;
	position: relative;
	overflow: hidden;
	ul {
		margin: 0;
	}
	img {
		margin: 0 0 24px;
	}
	span {
		font-size: rem-calc(17);
		color: $brand;
		margin-top: 3px;
		display: block;
	}
	.bio-content {
		padding: 20px;
		> *:last-child {
			margin-bottom: 0;
		}
	}
	.mfp-close {
		font-size: 50px;
		line-height: 1;
		color: $white;
		&::before {
			content: '';
			background-color: rgba(0, 0, 0, 0.4);
			width: 130px;
			height: 96px;
			transform: rotate(50deg);
			display: block;
			position: absolute;
			right: -59px;
			bottom: -17px;
			z-index: -1;
			transition: background-color .3s;
		}
		&:hover, &:focus {
			color: $white;
			&::before {
				background-color: $brand;
			}
		}
	}
	.bio-image {
		.icon-link {
			margin: 10px 0 15px;
			display: flex;
			align-items: center;
			justify-content: center;
			a {
				margin-left: 5px;
			}
		}
	}
}

@include breakpoint(385px) {
	.bio-modal {
		.bio-image {
			text-align: center;
		}
	}
}

@include breakpoint(850px) {
	.bio-modal {
		padding: 30px;
		overflow: hidden;
		display: flex;
		justify-content: space-between;
		.bio-image {
			flex-shrink: 0;
			img {
				margin: 0;
			}
		}
		.bio-content {
			padding: 0 0 0 32px;
		}
	}
}


.schedule-modal {
	background: $white;
	max-width: 1080px;
	width: auto;
	padding-bottom: 5px;
	margin: 20px auto;
	position: relative;
	overflow: hidden;
	padding: 20px 20px 30px;
	h2 {
		padding-right: 40px;
		margin-bottom: 28px;
	}
	h3 {
		margin-bottom: 10px;
		span {
			font-size: rem-calc(17);
			color: $brand;
			margin-top: 3px;
			display: block;
		}
	}
	img {
		margin-bottom: 10px;
	}
	.icon-link {
		margin: 0;
		display: flex;
		align-items: center;
		a {
			margin-left: 5px;
		}
	}
	.bio-schedule {
		display: grid;
		grid-gap: 50px 30px;
		grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
		justify-content: center;
	}
	.bio-schedule-card {
		max-width: 300px;
		margin: 0 auto;
	}
	.mfp-close {
		font-size: 50px;
		line-height: 1;
		color: $white;
		&::before {
			content: '';
			background-color: rgba(0, 0, 0, 0.4);
			width: 130px;
			height: 96px;
			transform: rotate(50deg);
			display: block;
			position: absolute;
			right: -59px;
			bottom: -17px;
			z-index: -1;
			transition: background-color .3s;
		}
		&:hover, &:focus {
			color: $white;
			&::before {
				background-color: $brand;
			}
		}
	}
}

@include breakpoint(768px) {
	.schedule-modal {
		padding: 30px 40px 40px;
		h3 {
			font-size: rem-calc(26);
		}
	}
}