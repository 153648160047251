/*------------------------------------*\
    @group Home Template
\*------------------------------------*/

.about-section {
	background-color: $brand-accent;
	color: $white;
	padding: $full-section-spacing 0;
	h2 {
		color: $white;
	}
	h3 {
		color: $white;
	}
	.member-logos {
		max-width: 500px;
		text-align: center;
		margin: 50px auto;
	}
	.logos {
		display: grid;
		grid-template-columns: repeat(3, auto);
		align-items: center;
		justify-content: center;
		grid-gap: 23px;
		img {
			margin: 0;
		}
	}
}

@include breakpoint(medium) {
	.about-section {
		h3 {
			font-size: rem-calc(21);
			line-height: 1.14;
		}
		.member-logos {
			margin-bottom: 100px;
		}
		.logos {
			grid-column-gap: 60px;
		}
	}
}

.contact-section {
	.contact-form {
		margin-bottom: 50px;
	}
	.contact-details {
		background: url('/images/cssimg/dark_bg_repeater.png') $brand-accent;
		color: $white;
		padding: 30px 20px 20px;
		h3 {
			color: $white;
		}
		p {
			background-image: url('/images/icons/ico_map_marker.svg');
			background-repeat: no-repeat;
			background-position: left top;
			@include font-size(small);
			padding-left: 30px;
			margin-bottom: 12px;
			&.icon-phone {
				background-image: url('/images/icons/ico_phone.svg');
			}
			&.icon-email {
				background-image: url('/images/icons/ico_envelope.svg');
				background-position: left 6px;
			}
		}
	}
	.contact-options {
		margin-bottom: 32px;
	}
}

@include breakpoint(750px) {
	.contact-section {
		.contact-info {
			display: grid;
			grid-template-columns: 1fr 1fr;
			grid-column-gap: 30px;
			align-items: start;
		}
		.contact-details {
			p {
				@include font-size(small-desktop);
			}
		}
	}
}

@include breakpoint(1000px) {
	.contact-section {
		.contact-form form {
			display: grid;
			grid-template-columns: 1fr 1fr;
			grid-column-gap: 30px;
			.field-full {
				grid-column: span 2;
			}
		}
		.contact-details {
			padding: 30px;
		}
		.contact-options {
			margin-bottom: 0;
		}
		.contact-details-list {
			display: grid;
			grid-template-columns: 1fr 1fr;
			grid-column-gap: 40px;
			margin-bottom: 24px;
		}
	}
}

.services-section {
	padding: 50px 0 75px;
}

/* @end Home Template */