.simple-callout {
	background: url('/images/cssimg/dark_bg_repeater.png') $brand-accent;
	max-width: 800px;
	margin: 50px auto 25px;
	color: $white;
	text-align: center;
	padding: 20px;
	h2 {
		color: $white;
	}
}

@include breakpoint(650px) {
	.simple-callout {
		padding: 30px;
		margin: 100px auto 50px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		h2 {
			margin: 0 20px 0 0;
			br {
				display: none;
			}
		}
		.button {
			flex-shrink: 0;
		}
	}
}

.split-callout {
	max-width: 415px;
	background: url('/images/cssimg/light_bg_repeater.png') $white;
	color: $main-color;
	text-align: center;
	padding: 30px 20px 50px;
	margin: 70px auto 24px;
	.heading {
		color: $main-color;
	}
	.text-divider {
		display: grid;
		grid-template-columns: 1fr auto 1fr;
		grid-gap: 20px;
		align-items: center;
		margin: 24px 0;
		&::before, &::after {
			content: '';
			height: 2px;
			background: $light-gray;
		}
	}
	.vertical-divider {
		display: none;
	}
}

@include breakpoint(800px) {
	.split-callout {
		max-width: 1080px;
		text-align: left;
		padding: 45px 60px 50px;
		margin-bottom: 100px;
		.heading {
			text-align: center;
			margin-bottom: 42px;
		}
		.callout-content {
			display: grid;
			grid-template-columns: 1fr auto 1fr;
			grid-column-gap: 60px;
		}
		.vertical-divider {
			background-color: $light-gray;
			width: 3px;
			display: block;
		}
		.text-divider {
			display: none;
		}
	}
}

@include breakpoint(1000px) {
	.split-callout {
		.mc-field-group {
			display: grid;
			grid-template-columns: 1fr auto;
			grid-column-gap: 20px;
			input {
				margin: 0;
			}
		}
	}
}