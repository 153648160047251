/*----------------------------------------------------------*\
    Buttons

    Most button styles below can be combined.
    For example, you could have small brand accent button by
	combining the .button.brand-accent.small classes.

\*----------------------------------------------------------*/


.button,
form input[type="submit"] {
	background-color: $yellow;
	font-size: rem-calc(20);
	font-weight: $main-bold;
	color: #333;
	text-transform: uppercase;
	line-height: 1;
	box-shadow: $box-shadow;
	padding: 10px 18px;
	border: 4px solid $white;
	display: inline-block;
	transition: background-color .3s;
	&:hover,
	&:focus {
		background-color: $brand;
		color: #333;
		cursor: pointer;
		text-decoration: none;
	}
}
.button.small {
	font-size: rem-calc(14);
	padding: 8px 13px;
}
.button.large {
	font-size: rem-calc(24);
	padding: 18px 23px;
}
.button.short {
	padding-top: 8px;
	padding-bottom: 8px;
}
.button.alt {
	background: $brand;
	color: $white;
	&:hover,
	&:focus {
		background: $brand-accent;
	}
}