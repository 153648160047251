.payment-form {
	max-width: 800px;
	min-height: 600px;
	.field-group {
		display: grid;
	}
	.field-amount {
		max-width: 200px;
	}
	.field-cc {
		max-width: 400px;
	}
	.stripe-card {
		height: 3.125rem;
		padding: .75rem;
		border: 1px solid #e6e6e6;
		box-shadow: inset 0 1px 1px rgba(0,0,0,.1);
		margin-bottom: 32px;
	}
}

@include breakpoint(400px) {
	.payment-form {
		.field-group {
			grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
			grid-column-gap: 32px;
		}
	}
}

//Success styles
.successsvg {
	h2 {
		font-size: rem-calc(24);
		text-align: center;
		line-height: 1.2;
		margin: 0;
	}
	svg {
		max-width: 300px;
		display: block;
		margin: 0 auto;
	}
}


//Loading styles
.payment-processing {
  width: 100%;
  text-align: center;
  h2 {
  	font-size: rem-calc(24);
  }
}
.payment-processing-indicator {
  display: inline-block;
  margin-bottom: 10px;
  position: relative;
  left: -20px;
}
.payment-processing-loader{
    width: 12px;
    height: 12px;
    border-radius: 50%;
    animation: payment-loader 1s linear infinite alternate;
    position: relative;
    left: 0;
}

@keyframes payment-loader {
   0%{
        background-color: rgba(#f4ba44, 1);
        box-shadow: 18px 0px 0px 0px rgba(#f4ba44,0.2),
                    36px 0px 0px 0px rgba(#f4ba44,0.2);
      }
    25%{
        background-color: rgba(#f4ba44, 0.4);
        box-shadow: 18px 0px 0px 0px rgba(#f4ba44,2),
                    36px 0px 0px 0px rgba(#f4ba44,0.2);
    }
    75%{ background-color: rgba(#f4ba44, 0.4);
        box-shadow: 18px 0px 0px 0px rgba(#f4ba44,0.2),
                    36px 0px 0px 0px rgba(#f4ba44,1);
      }
}