.page-heading {
	min-height: 415px;
	background-color: #3a404a;
	background-image: url('https://harriscpa.imgix.net/page-heading/bg_boise_skyline.jpg?auto=format');
	background-size: cover;
	background-position: center bottom;
	display: flex;
	align-items: flex-end;
	position: relative;
	padding: 100px 0 24px;
	@media  only screen and (-webkit-min-device-pixel-ratio: 1.3),
	only screen and (-o-min-device-pixel-ratio: 13/10),
	only screen and (min-resolution: 120dpi) {
		background-image: url('https://harriscpa.imgix.net/page-heading/bg_boise_skyline@2x.jpg?auto=format');
	}
	&::before {
		content: '';
		background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.75) 0%, rgba(0, 0, 0, 0) 100%);
		height: 250px;
		width: 100%;
		display: block;
		position: absolute;
		top: 0;
		left: 0;
	}
	&::after {
		content: '';
		background-image: linear-gradient(-180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, .75) 100%);
		height: 110px;
		width: 100%;
		display: block;
		position: absolute;
		bottom: 0;
		left: 0;
	}
	.inner {
		height: 100%;
		width: 100%;
		position: relative;
		z-index: 50;
	}
	h1, .h1 {
		color: $white;
		padding: 0;
		margin: 0;
		a {
			color: $white;
			border-bottom: none;
			&:hover, &:focus {
				color: $brand;
			}
		}
	}
}

@include breakpoint(medium) {
	.page-heading {
		min-height: 440px;
		background-image: url('https://harriscpa.imgix.net/page-heading/bg_boise_skyline_med.jpg?auto=format');
		@media  only screen and (-webkit-min-device-pixel-ratio: 1.3),
		only screen and (-o-min-device-pixel-ratio: 13/10),
		only screen and (min-resolution: 120dpi) {
			background-image: url('https://harriscpa.imgix.net/page-heading/bg_boise_skyline_med@2x.jpg?auto=format');
		}
	}
}