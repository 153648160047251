.blog-post-preview {
	margin-bottom: 70px;
}
.blog-post-title {
	text-wrap: pretty;
	margin: 0 0 8px;
	a {
		color: $brand-accent;
		border-bottom: none;
		padding: 0;
		margin: 0;
		&:hover, &:focus {
			color: $brand;
		}
	}
}
.blog-post-byline {
	@include font-size(small);
	border-bottom: solid  2px $light-gray;
	padding-bottom: 6px;
	margin-bottom: 30px;
	@include breakpoint(medium) {
		@include font-size(small-desktop);
	}
}
.blog-page-nav {
	display: grid;
	grid-template-columns: repeat(auto-fit, 40px);
	grid-gap: 10px;
	align-items: end;
	a {
		color: #333;
		border: solid 2px $light-gray;
		transition: background-color .3s, color .3s;
		&:hover, &:focus {
			background-color: $brand;
			color: $white;
		}
	}
	span, a {
		width: 40px;
		height: 40px;
		text-align: center;
		line-height: 36px;
		display: inline-block;
	}
	span {
		background-color: $brand;
		color: $white;
		border: solid 2px $brand;
	}
	.page-separator {
		background: none;
		color: $light-gray;
		align-self: end;
		border: none;
	}
}
.blog-aside {
	max-width: 415px;
	background: url('/images/cssimg/dark_bg_repeater.png') $brand-accent;
	color: $white;
	text-align: center;
	padding: 30px 20px 50px;
	margin: 70px auto 24px;
	h2 {
		color: $white;
	}
	.text-divider {
		display: grid;
		grid-template-columns: 1fr auto 1fr;
		grid-gap: 20px;
		align-items: center;
		margin: 24px 0;
		&::before, &::after {
			content: '';
			height: 2px;
			background: $light-gray;
		}
	}
}

@include breakpoint(700px) {
	.blog-overview {
		display: grid;
		grid-template-columns: .6fr .4fr;
		align-items: start;
		grid-gap: 40px;
	}
	.blog-page-nav {
		margin-bottom: 24px;
	}
	.blog-aside {
		padding: 30px 30px 70px;
		margin: 0;
	}
}

@include breakpoint(large) {
	.blog-overview {
		grid-template-columns: auto max-content;
		grid-gap: 80px;
	}
}