/*----------------------------------------------------------*\
	Callouts

	Some of the callout styles come from the Foundation
	settings file. The styles listed here apply to the
	default callouts. Additional callout options can be
	seen on the styleguide.

\*----------------------------------------------------------*/

.callout {
	background: $brand;
	.heading, h3 {
		font-size: 1.4375em;
		color: $white;
	}
	p {
		font-size: rem-calc(14);
		line-height: 1.25;
		margin: 0 0 1rem;
	}
	.button {
		font-size: 1rem;
		padding: .5rem 1rem;
		margin: 0;
	}
}